import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.66732 1.33333C1.93065 1.33333 1.33398 1.92999 1.33398 2.66666V11.3333C1.33398 11.7013 1.63265 12 2.00065 12C2.36865 12 2.66732 11.7013 2.66732 11.3333V2.66666H11.334C11.702 2.66666 12.0007 2.36799 12.0007 1.99999C12.0007 1.63199 11.702 1.33333 11.334 1.33333H2.66732ZM5.33398 3.99999C4.59732 3.99999 4.00065 4.59666 4.00065 5.33333V13.3333C4.00065 14.07 4.59732 14.6667 5.33398 14.6667H13.334C14.0707 14.6667 14.6673 14.07 14.6673 13.3333V5.33333C14.6673 4.59666 14.0707 3.99999 13.334 3.99999H5.33398ZM5.33398 5.33333H13.334V13.3333H5.33398V5.33333Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
