import {
  Box,
  Button,
  Flex,
  InjectedModalProps,
  LinkExternal,
  Message,
  Skeleton,
  Text,
  CopyAddress,
  FlexGap,
} from '@pancakeswap/uikit'
import styled from 'styled-components'
import { ChainId, WNATIVE } from '@pancakeswap/sdk'
import { FetchStatus } from 'config/constants/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@pancakeswap/localization'
import useAuth from 'hooks/useAuth'
import useNativeCurrency from 'hooks/useNativeCurrency'
import useTokenBalance, { useGetCakeBalance } from 'hooks/useTokenBalance'
import { ChainLogo } from 'components/Logo/ChainLogo'

import { getBlockExploreLink, getBlockExploreName } from 'utils'
import { formatBigNumber, getFullDisplayBalance } from '@pancakeswap/utils/formatBalance'
import { useBalance } from 'wagmi'
import { useSidNameForAddress } from 'hooks/useSid'
import CakeBenefitsCard from './CakeBenefitsCard'

const COLORS = {
  ETH: '#627EEA',
  BNB: '#14151A',
}

const StyledCopyAddress = styled(CopyAddress)`
  > div {
    background-color: #162237;
    padding: 12px 16px;
    align-items: center;
    gap: 9px;
  }
`

interface WalletInfoProps {
  hasLowNativeBalance: boolean
  switchView: (newIndex: number) => void
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowNativeBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account, chainId, chain } = useActiveWeb3React()
  const { sidName } = useSidNameForAddress(account)
  const isBSC = chainId === ChainId.BSC
  const bnbBalance = useBalance({ address: account, chainId: ChainId.BSC })
  const nativeBalance = useBalance({ address: account, enabled: !isBSC })
  const native = useNativeCurrency()
  const wNativeToken = !isBSC ? WNATIVE[chainId] : null
  const wBNBToken = WNATIVE[ChainId.BSC]
  const { balance: wNativeBalance, fetchStatus: wNativeFetchStatus } = useTokenBalance(wNativeToken?.address)
  const { balance: wBNBBalance, fetchStatus: wBNBFetchStatus } = useTokenBalance(wBNBToken?.address, true)
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useGetCakeBalance()
  const { logout } = useAuth()

  const handleLogout = () => {
    onDismiss?.()
    logout()
  }

  return (
    <>
      <Text fontWeight="400" fontSize="14px" lineHeight="140%" color="#FFFFFF99 !important" mb="8px">
        {t('Your address')}
      </Text>
      <FlexGap flexDirection="column" mb="24px" gap="8px">
        <StyledCopyAddress tooltipMessage={t('Copied')} account={account} />
        {sidName ? <Text color="#fff">{sidName}</Text> : null}
      </FlexGap>
      {hasLowNativeBalance && (
        <Message variant="warning" mb="24px">
          <Box>
            <Text fontWeight="bold" fontSize="14px">
              {t('%currency% Balance Low', {
                currency: native.symbol,
              })}
            </Text>
            <Text as="p" fontSize="14px">
              {t('You need %currency% for transaction fees.', {
                currency: native.symbol,
              })}
            </Text>
          </Box>
        </Message>
      )}
      {!isBSC && chain && (
        <Box>
          {/* <Flex justifyContent="space-between" alignItems="center" mb="24px">
            <Flex bg="#375991" borderRadius="16px" p="4px 8px">
              <ChainLogo chainId={chain.id} />
              <Text color="white" ml="4px">
                {chain.name}
              </Text>
            </Flex>
            <LinkExternal href={getBlockExploreLink(account, 'address', chainId)}>
              {getBlockExploreName(chainId)}
            </LinkExternal>
          </Flex> */}
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="#FFFFFF99" fontWeight="400" fontSize="16px" lineHeight="140%">
              {native.symbol} {t('Balance')}
            </Text>
            {!nativeBalance.isFetched ? (
              <Skeleton height="22px" width="60px" />
            ) : (
              <Text>{formatBigNumber(nativeBalance.data.value, 6)}</Text>
            )}
          </Flex>
        </Box>
      )}
      {wNativeBalance.gt(0) && (
        <Flex alignItems="center" justifyContent="space-between">
          <Text color="#FFFFFF99">
            {wNativeToken.symbol} {t('Balance')}
          </Text>
          {wNativeFetchStatus !== FetchStatus.Fetched ? (
            <Skeleton height="22px" width="60px" />
          ) : (
            <Text>{getFullDisplayBalance(wNativeBalance, wNativeToken.decimals, 6)}</Text>
          )}
        </Flex>
      )}
      <Box mb="24px">
        {/* <Flex justifyContent="space-between" alignItems="center" mb="24px">
          <Flex bg="#375991" borderRadius="16px" p="4px 8px">
            <ChainLogo chainId={ChainId.BSC} />
            <Text color="white" ml="8px">
              BNB Smart Chain
            </Text>
          </Flex>
          <LinkExternal isBscScan href={getBlockExploreLink(account, 'address', ChainId.BSC)}>
            {getBlockExploreName(ChainId.BSC)}
          </LinkExternal>
        </Flex> */}
        {/* <Flex alignItems="center" justifyContent="space-between">
          <Text fontWeight="400" fontSize="16px" lineHeight="140%" color="#FFFFFF99">
            BNB {t('Balance')}
          </Text>
          {!bnbBalance.isFetched ? (
            <Skeleton height="22px" width="60px" />
          ) : (
            <Text>{formatBigNumber(bnbBalance.data.value, 6)}</Text>
          )}
        </Flex>
        {wBNBBalance.gt(0) && (
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontWeight="400" fontSize="16px" lineHeight="140%" color="#FFFFFF99">
              WBNB {t('Balance')}
            </Text>
            {wBNBFetchStatus !== FetchStatus.Fetched ? (
              <Skeleton height="22px" width="60px" />
            ) : (
              <Text>{getFullDisplayBalance(wBNBBalance, wBNBToken.decimals, 6)}</Text>
            )}
          </Flex>
        )} */}
        {/* <Flex alignItems="center" justifyContent="space-between" mt="8px">
          <Text fontWeight="400" fontSize="16px" lineHeight="140%" color="#FFFFFF99">
            {t('SKELETON Balance')}
          </Text>
          {cakeFetchStatus !== FetchStatus.Fetched ? (
            <Skeleton height="22px" width="60px" />
          ) : (
            <Text>{formatBigNumber(cakeBalance, 3)}</Text>
          )}
        </Flex> */}
      </Box>
      {/* <CakeBenefitsCard onDismiss={onDismiss} /> */}
      <Button className="secondary" width="100%" minHeight={40} onClick={handleLogout}>
        {t('Disconnect Wallet')}
      </Button>
    </>
  )
}

export default WalletInfo
