import { Call, MultiCallV2 } from '@pancakeswap/multicall'
import { ChainId, Currency, ERC20Token } from '@pancakeswap/sdk'
import { CAKE } from '@pancakeswap/tokens'
import { tickToPrice } from '@pancakeswap/v3-sdk'
import BN from 'bignumber.js'
import { BigNumber, FixedNumber } from 'ethers'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import chunk from 'lodash/chunk'
import { CHAIN_ID_TO_CHAIN_NAME, DEFAULT_COMMON_PRICE, PriceHelper } from '../constants/common'
import { FIXED_ZERO } from './const'
import { FarmConfigV3, FarmV3Data, FarmV3DataWithPrice } from './types'

export async function farmV3FetchFarms({
  farms,
  multicallv2,
  masterChefAddress,
  chainId,
  totalAllocPoint,
  commonPrice,
}: {
  farms: FarmConfigV3[]
  multicallv2: MultiCallV2
  masterChefAddress: string
  chainId: number
  totalAllocPoint: BigNumber
  commonPrice: CommonPrice
}) {
  // const [poolInfos, cakePrice, v3PoolData] = await Promise.all([
  //   fetchPoolInfos(farms, chainId, multicallv2, masterChefAddress),
  //   (await fetch('https://farms-api.pancakeswap.com/price/cake')).json(),
  //   fetchV3Pools(farms, chainId, multicallv2),
  // ])

  // FIX THIS LATER

  const cakePrice = { price: '1.5701', updatedAt: '2023-06-06T07:45:49.064Z' }

  const [poolInfos, v3PoolData] = await Promise.all([
    fetchPoolInfos(farms, chainId, multicallv2, masterChefAddress),
    fetchV3Pools(farms, chainId, multicallv2),
  ])

  const lmPoolInfos = await fetchLmPools(
    v3PoolData.map((v3Pool) => (v3Pool[1] ? v3Pool[1][0] : null)).filter(Boolean) as string[],
    chainId,
    multicallv2,
  )

  const farmsData = farms
    .map((farm, index) => {
      const { token, quoteToken, ...f } = farm
      if (!v3PoolData[index][1]) {
        return null
      }

      const lmPoolAddress = v3PoolData[index][1][0]

      return {
        ...f,
        token,
        quoteToken,
        lmPool: lmPoolAddress,
        lmPoolLiquidity: lmPoolInfos[lmPoolAddress].liquidity,
        _rewardGrowthGlobalX128: lmPoolInfos[lmPoolAddress].rewardGrowthGlobalX128,
        ...getV3FarmsDynamicData({
          ...(v3PoolData[index][0] as any),
          token0: farm.token,
          token1: farm.quoteToken,
        }),
        ...getFarmAllocation({
          allocPoint: poolInfos[index]?.allocPoint,
          totalAllocPoint,
        }),
      }
    })
    .filter(Boolean) as FarmV3Data[]

  const combinedCommonPrice: CommonPrice = {
    ...DEFAULT_COMMON_PRICE[chainId as ChainId],
    ...commonPrice,
  }

  const farmsWithPrice = getFarmsPrices(farmsData, cakePrice.price, combinedCommonPrice)

  return farmsWithPrice
}

const masterchefV3Abi = [
  {
    inputs: [
      { internalType: 'contract IERC20', name: '_rewardToken', type: 'address' },
      { internalType: 'contract INonfungiblePositionManager', name: '_nonfungiblePositionManager', type: 'address' },
      { internalType: 'address', name: '_WETH', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  { inputs: [{ internalType: 'uint256', name: 'pid', type: 'uint256' }], name: 'DuplicatedPool', type: 'error' },
  { inputs: [], name: 'InconsistentAmount', type: 'error' },
  { inputs: [], name: 'InsufficientAmount', type: 'error' },
  { inputs: [], name: 'InvalidNFT', type: 'error' },
  { inputs: [], name: 'InvalidPeriodDuration', type: 'error' },
  { inputs: [], name: 'InvalidPid', type: 'error' },
  { inputs: [], name: 'NoBalance', type: 'error' },
  { inputs: [], name: 'NoLMPool', type: 'error' },
  { inputs: [], name: 'NoLiquidity', type: 'error' },
  { inputs: [], name: 'NotEmpty', type: 'error' },
  { inputs: [], name: 'NotOwner', type: 'error' },
  { inputs: [], name: 'NotOwnerOrOperator', type: 'error' },
  { inputs: [], name: 'NotSkeletonNFT', type: 'error' },
  { inputs: [], name: 'WrongReceiver', type: 'error' },
  { inputs: [], name: 'ZeroAddress', type: 'error' },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'pid', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'allocPoint', type: 'uint256' },
      { indexed: true, internalType: 'contract ISkeletonV3Pool', name: 'v3Pool', type: 'address' },
      { indexed: true, internalType: 'contract ILMPool', name: 'lmPool', type: 'address' },
    ],
    name: 'AddPool',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'from', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'pid', type: 'uint256' },
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'liquidity', type: 'uint256' },
      { indexed: false, internalType: 'int24', name: 'tickLower', type: 'int24' },
      { indexed: false, internalType: 'int24', name: 'tickUpper', type: 'int24' },
    ],
    name: 'Deposit',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
      { indexed: false, internalType: 'address', name: 'to', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'pid', type: 'uint256' },
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'reward', type: 'uint256' },
    ],
    name: 'Harvest',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'deployer', type: 'address' }],
    name: 'NewLMPoolDeployerAddress',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'operator', type: 'address' }],
    name: 'NewOperatorAddress',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'uint256', name: 'periodDuration', type: 'uint256' }],
    name: 'NewPeriodDuration',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'receiver', type: 'address' }],
    name: 'NewReceiver',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'periodNumber', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'startTime', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'endTime', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'rewardTokenPerSecond', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'rewardTokenAmount', type: 'uint256' },
    ],
    name: 'NewUpkeepPeriod',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'bool', name: 'emergency', type: 'bool' }],
    name: 'SetEmergency',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'pid', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'allocPoint', type: 'uint256' },
    ],
    name: 'SetPool',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'farmBoostContract', type: 'address' }],
    name: 'UpdateFarmBoostContract',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'from', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'pid', type: 'uint256' },
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { indexed: false, internalType: 'int128', name: 'liquidity', type: 'int128' },
      { indexed: false, internalType: 'int24', name: 'tickLower', type: 'int24' },
      { indexed: false, internalType: 'int24', name: 'tickUpper', type: 'int24' },
    ],
    name: 'UpdateLiquidity',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'periodNumber', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'oldEndTime', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newEndTime', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'remainingRewardToken', type: 'uint256' },
    ],
    name: 'UpdateUpkeepPeriod',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'from', type: 'address' },
      { indexed: false, internalType: 'address', name: 'to', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'pid', type: 'uint256' },
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
    ],
    name: 'Withdraw',
    type: 'event',
  },
  {
    inputs: [],
    name: 'BOOST_PRECISION',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'FARM_BOOSTER',
    outputs: [{ internalType: 'contract IFarmBooster', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'LMPoolDeployer',
    outputs: [{ internalType: 'contract ILMPoolDeployer', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'MAX_BOOST_PRECISION',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'MAX_DURATION',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'MIN_DURATION',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'PERIOD_DURATION',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'PRECISION',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'WETH',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_allocPoint', type: 'uint256' },
      { internalType: 'contract ISkeletonV3Pool', name: '_v3Pool', type: 'address' },
      { internalType: 'bool', name: '_withUpdate', type: 'bool' },
    ],
    name: 'add',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'burn',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'address', name: 'recipient', type: 'address' },
          { internalType: 'uint128', name: 'amount0Max', type: 'uint128' },
          { internalType: 'uint128', name: 'amount1Max', type: 'uint128' },
        ],
        internalType: 'struct INonfungiblePositionManagerStruct.CollectParams',
        name: 'params',
        type: 'tuple',
      },
    ],
    name: 'collect',
    outputs: [
      { internalType: 'uint256', name: 'amount0', type: 'uint256' },
      { internalType: 'uint256', name: 'amount1', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'address', name: 'recipient', type: 'address' },
          { internalType: 'uint128', name: 'amount0Max', type: 'uint128' },
          { internalType: 'uint128', name: 'amount1Max', type: 'uint128' },
        ],
        internalType: 'struct INonfungiblePositionManagerStruct.CollectParams',
        name: 'params',
        type: 'tuple',
      },
      { internalType: 'address', name: 'to', type: 'address' },
    ],
    name: 'collectTo',
    outputs: [
      { internalType: 'uint256', name: 'amount0', type: 'uint256' },
      { internalType: 'uint256', name: 'amount1', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint128', name: 'liquidity', type: 'uint128' },
          { internalType: 'uint256', name: 'amount0Min', type: 'uint256' },
          { internalType: 'uint256', name: 'amount1Min', type: 'uint256' },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' },
        ],
        internalType: 'struct INonfungiblePositionManagerStruct.DecreaseLiquidityParams',
        name: 'params',
        type: 'tuple',
      },
    ],
    name: 'decreaseLiquidity',
    outputs: [
      { internalType: 'uint256', name: 'amount0', type: 'uint256' },
      { internalType: 'uint256', name: 'amount1', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'emergency',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_v3Pool', type: 'address' }],
    name: 'getLatestPeriodInfo',
    outputs: [
      { internalType: 'uint256', name: 'rewardTokenPerSecond', type: 'uint256' },
      { internalType: 'uint256', name: 'endTime', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_pid', type: 'uint256' }],
    name: 'getLatestPeriodInfoByPid',
    outputs: [
      { internalType: 'uint256', name: 'rewardTokenPerSecond', type: 'uint256' },
      { internalType: 'uint256', name: 'endTime', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
      { internalType: 'address', name: '_to', type: 'address' },
    ],
    name: 'harvest',
    outputs: [{ internalType: 'uint256', name: 'reward', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint256', name: 'amount0Desired', type: 'uint256' },
          { internalType: 'uint256', name: 'amount1Desired', type: 'uint256' },
          { internalType: 'uint256', name: 'amount0Min', type: 'uint256' },
          { internalType: 'uint256', name: 'amount1Min', type: 'uint256' },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' },
        ],
        internalType: 'struct INonfungiblePositionManagerStruct.IncreaseLiquidityParams',
        name: 'params',
        type: 'tuple',
      },
    ],
    name: 'increaseLiquidity',
    outputs: [
      { internalType: 'uint128', name: 'liquidity', type: 'uint128' },
      { internalType: 'uint256', name: 'amount0', type: 'uint256' },
      { internalType: 'uint256', name: 'amount1', type: 'uint256' },
    ],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'latestPeriodEndTime',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'latestPeriodNumber',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'latestPeriodRewardTokenPerSecond',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  // {
  //   inputs: [],
  //   name: 'latestPeriodRewardTokenPerSecond',
  //   outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],  fix later
  //   stateMutability: 'view',
  //   type: 'function',
  // },
  {
    inputs: [],
    name: 'latestPeriodStartTime',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes[]', name: 'data', type: 'bytes[]' }],
    name: 'multicall',
    outputs: [{ internalType: 'bytes[]', name: 'results', type: 'bytes[]' }],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'nonfungiblePositionManager',
    outputs: [{ internalType: 'contract INonfungiblePositionManager', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '_from', type: 'address' },
      { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
      { internalType: 'bytes', name: '', type: 'bytes' },
    ],
    name: 'onERC721Received',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'operatorAddress',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'pendingRewardToken',
    outputs: [{ internalType: 'uint256', name: 'reward', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'poolInfo',
    outputs: [
      { internalType: 'uint256', name: 'allocPoint', type: 'uint256' },
      { internalType: 'contract ISkeletonV3Pool', name: 'v3Pool', type: 'address' },
      { internalType: 'address', name: 'token0', type: 'address' },
      { internalType: 'address', name: 'token1', type: 'address' },
      { internalType: 'uint24', name: 'fee', type: 'uint24' },
      { internalType: 'uint256', name: 'totalLiquidity', type: 'uint256' },
      { internalType: 'uint256', name: 'totalBoostLiquidity', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'poolLength',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'receiver',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [],
    name: 'rewardToken',
    outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'rewardTokenAmountBelongToMC',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_pid', type: 'uint256' },
      { internalType: 'uint256', name: '_allocPoint', type: 'uint256' },
      { internalType: 'bool', name: '_withUpdate', type: 'bool' },
    ],
    name: 'set',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bool', name: '_emergency', type: 'bool' }],
    name: 'setEmergency',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract ILMPoolDeployer', name: '_LMPoolDeployer', type: 'address' }],
    name: 'setLMPoolDeployer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_operatorAddress', type: 'address' }],
    name: 'setOperator',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_periodDuration', type: 'uint256' }],
    name: 'setPeriodDuration',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_receiver', type: 'address' }],
    name: 'setReceiver',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'token', type: 'address' },
      { internalType: 'uint256', name: 'amountMinimum', type: 'uint256' },
      { internalType: 'address', name: 'recipient', type: 'address' },
    ],
    name: 'sweepToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'uint256', name: 'index', type: 'uint256' },
    ],
    name: 'tokenOfOwnerByIndex',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalAllocPoint',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amountMinimum', type: 'uint256' },
      { internalType: 'address', name: 'recipient', type: 'address' },
    ],
    name: 'unwrapWETH9',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
      { internalType: 'uint256', name: '_newMultiplier', type: 'uint256' },
    ],
    name: 'updateBoostMultiplier',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_newFarmBoostContract', type: 'address' }],
    name: 'updateFarmBoostContract',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'updateLiquidity',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256[]', name: 'pids', type: 'uint256[]' }],
    name: 'updatePools',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
      { internalType: 'uint256', name: '_duration', type: 'uint256' },
      { internalType: 'bool', name: '_withUpdate', type: 'bool' },
    ],
    name: 'upkeep',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'userPositionInfos',
    outputs: [
      { internalType: 'uint128', name: 'liquidity', type: 'uint128' },
      { internalType: 'uint128', name: 'boostLiquidity', type: 'uint128' },
      { internalType: 'int24', name: 'tickLower', type: 'int24' },
      { internalType: 'int24', name: 'tickUpper', type: 'int24' },
      { internalType: 'uint256', name: 'rewardGrowthInside', type: 'uint256' },
      { internalType: 'uint256', name: 'reward', type: 'uint256' },
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'uint256', name: 'pid', type: 'uint256' },
      { internalType: 'uint256', name: 'boostMultiplier', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'v3PoolAddressPid',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
      { internalType: 'address', name: '_to', type: 'address' },
    ],
    name: 'withdraw',
    outputs: [{ internalType: 'uint256', name: 'reward', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { stateMutability: 'payable', type: 'receive' },
]

export async function fetchMasterChefV3Data({
  multicallv2,
  masterChefAddress,
  chainId,
}: {
  multicallv2: MultiCallV2
  masterChefAddress: string
  chainId: number
}): Promise<{
  poolLength: BigNumber
  totalAllocPoint: BigNumber
  latestPeriodRewardTokenPerSecond: BigNumber
}> {
  const [[poolLength], [totalAllocPoint], [latestPeriodRewardTokenPerSecond]] = await multicallv2({
    abi: masterchefV3Abi,
    calls: [
      {
        address: masterChefAddress,
        name: 'poolLength',
      },
      {
        address: masterChefAddress,
        name: 'totalAllocPoint',
      },
      {
        address: masterChefAddress,
        name: 'latestPeriodRewardTokenPerSecond',
      },
      // {
      //   address: masterChefAddress,
      //   name: 'latestPeriodRewardTokenPerSecond',  fix later
      // },
    ],
    chainId,
  })

  return {
    poolLength,
    totalAllocPoint,
    latestPeriodRewardTokenPerSecond,
  }
}

const fetchPoolInfos = async (
  farms: FarmConfigV3[],
  chainId: number,
  multicallv2: MultiCallV2,
  masterChefAddress: string,
): Promise<
  {
    allocPoint: BigNumber
    v3Pool: string
    token0: string
    token1: string
    fee: number
    totalLiquidity: BigNumber
    totalBoostLiquidity: BigNumber
  }[]
> => {
  try {
    const calls: Call[] = farms.map((farm) => ({
      address: masterChefAddress,
      name: 'poolInfo',
      params: [farm.pid],
    }))

    const masterChefMultiCallResult = await multicallv2({
      abi: masterchefV3Abi,
      calls,
      chainId,
    })

    let masterChefChunkedResultCounter = 0
    return calls.map((masterChefCall) => {
      if (masterChefCall === null) {
        return null
      }
      const data = masterChefMultiCallResult[masterChefChunkedResultCounter]
      masterChefChunkedResultCounter++
      return data
    })
  } catch (error) {
    console.error('MasterChef Pool info data error', error)
    throw error
  }
}

export const getCakeApr = (poolWeight: string, activeTvlUSD: BN, cakePriceUSD: string, cakePerSecond: string) => {
  let cakeApr = '0'

  if (
    !cakePriceUSD ||
    !activeTvlUSD ||
    activeTvlUSD.isZero() ||
    !cakePerSecond ||
    +cakePerSecond === 0 ||
    !poolWeight
  ) {
    return cakeApr
  }

  const cakeRewardPerYear = new BN(cakePerSecond).times(365 * 60 * 60 * 24)

  const cakeRewardPerYearForPool = new BN(poolWeight)
    .times(cakeRewardPerYear)
    .times(cakePriceUSD)
    .div(activeTvlUSD.toString())
    .times(100)

  if (!cakeRewardPerYearForPool.isZero()) {
    cakeApr = cakeRewardPerYearForPool.toFixed(6)
  }

  return cakeApr
}

const getV3FarmsDynamicData = ({ token0, token1, tick }: { token0: ERC20Token; token1: ERC20Token; tick: number }) => {
  const tokenPriceVsQuote = tickToPrice(token0, token1, tick)

  return {
    tokenPriceVsQuote: tokenPriceVsQuote.toSignificant(6),
  }
}

const getFarmAllocation = ({
  allocPoint,
  totalAllocPoint,
}: {
  allocPoint?: BigNumber
  totalAllocPoint?: BigNumber
}) => {
  const _allocPoint = allocPoint ? FixedNumber.from(allocPoint) : FIXED_ZERO
  const poolWeight =
    !totalAllocPoint?.isZero() && !_allocPoint.isZero()
      ? _allocPoint.divUnsafe(FixedNumber.from(totalAllocPoint))
      : FIXED_ZERO

  return {
    poolWeight: poolWeight.toString(),
    multiplier: !_allocPoint.isZero() ? `${+_allocPoint.divUnsafe(FixedNumber.from(10)).toString()}X` : `0X`,
  }
}

const lmPoolAbi = [
  {
    inputs: [],
    name: 'lmLiquidity',
    outputs: [
      {
        internalType: 'uint128',
        name: '',
        type: 'uint128',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'rewardGrowthGlobalX128',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const

const v3PoolAbi = [
  {
    inputs: [],
    name: 'lmPool',
    outputs: [{ internalType: 'contract IPancakeV3LmPool', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'slot0',
    outputs: [
      { internalType: 'uint160', name: 'sqrtPriceX96', type: 'uint160' },
      { internalType: 'int24', name: 'tick', type: 'int24' },
      { internalType: 'uint16', name: 'observationIndex', type: 'uint16' },
      { internalType: 'uint16', name: 'observationCardinality', type: 'uint16' },
      { internalType: 'uint16', name: 'observationCardinalityNext', type: 'uint16' },
      { internalType: 'uint32', name: 'feeProtocol', type: 'uint32' },
      { internalType: 'bool', name: 'unlocked', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const

type Slot0 = {
  sqrtPriceX96: BigNumber
  tick: number
  observationIndex: number
  observationCardinality: number
  observationCardinalityNext: number
  feeProtocol: number
  unlocked: boolean
}
type LmPool = `0x${string}`

type LmLiquidity = BigNumber
type LmRewardGrowthGlobalX128 = BigNumber

async function fetchLmPools(lmPoolAddresses: string[], chainId: number, multicallv2: MultiCallV2) {
  const lmPoolCalls = lmPoolAddresses.flatMap((address) => [
    {
      address,
      name: 'lmLiquidity',
    },
    {
      address,
      name: 'rewardGrowthGlobalX128',
    },
  ])

  const chunkSize = lmPoolCalls.length / lmPoolAddresses.length

  const resp = await multicallv2({
    abi: lmPoolAbi,
    calls: lmPoolCalls,
    chainId,
  })

  const chunked = chunk(resp, chunkSize) as [LmLiquidity, LmRewardGrowthGlobalX128][]

  const lmPools: Record<
    string,
    {
      liquidity: string
      rewardGrowthGlobalX128: string
    }
  > = {}

  for (const [index, res] of chunked.entries()) {
    lmPools[lmPoolAddresses[index]] = {
      liquidity: res?.[0]?.toString() ?? '0',
      rewardGrowthGlobalX128: res?.[1]?.toString() ?? '0',
    }
  }

  return lmPools
}

async function fetchV3Pools(farms: FarmConfigV3[], chainId: number, multicallv2: MultiCallV2) {
  const v3PoolCalls = farms.flatMap((f) => [
    {
      address: f.lpAddress,
      name: 'slot0',
    },
    {
      address: f.lpAddress,
      name: 'lmPool',
    },
  ])

  const chunkSize = v3PoolCalls.length / farms.length
  const resp = await multicallv2({
    abi: v3PoolAbi,
    calls: v3PoolCalls,
    chainId,
  })

  return chunk(resp, chunkSize) as [Slot0, LmPool][]
}

export type LPTvl = {
  token0: string
  token1: string
  updatedAt: string
}

export type TvlMap = {
  [key: string]: LPTvl | null
}

export type CommonPrice = {
  [address: string]: string
}

// export const fetchCommonTokenUSDValue = async (priceHelper?: PriceHelper): Promise<CommonPrice> => {
//   const commonTokenUSDValue: CommonPrice = {}
//   if (priceHelper && priceHelper.list.length > 0) {
//     const list = priceHelper.list.map((token) => `${priceHelper.chain}:${token.address}`).join(',')

//     const result: { coins: { [key: string]: { price: string } } } = await fetch(
//       `https://coins.llama.fi/prices/current/${list}`,
//     ).then((res) => res.json())

//     Object.entries(result.coins || {}).forEach(([key, value]) => {
//       const [, address] = key.split(':')
//       commonTokenUSDValue[address] = value.price
//     })
//   }

//   // fix later

//   return {
//     '0x0230718C6Cab682F420aA5E6463EA51219E72078': '26.798',
//     '0x0EC52Cbf170D4351ea7a96941976eAf20B966818': '1',
//   }

//   // return commonTokenUSDValue
// }

export const fetchCommonTokenUSDValue = async (priceHelper?: PriceHelper): Promise<CommonPrice> => {
  return fetchTokenUSDValues(priceHelper?.list || [])
}

export const fetchTokenUSDValues = async (currencies: Currency[] = []): Promise<CommonPrice> => {
  const commonTokenUSDValue: CommonPrice = {}
  if (currencies.length > 0) {
    const list = currencies
      .map((currency) => `${CHAIN_ID_TO_CHAIN_NAME[currency.chainId as ChainId]}:${currency.wrapped.address}`)
      .join(',')
    const result: { coins: { [key: string]: { price: string } } } = await fetch(
      `https://coins.llama.fi/prices/current/${list}`,
    ).then((res) => res.json())

    Object.entries(result.coins || {}).forEach(([key, value]) => {
      const [, address] = key.split(':')
      commonTokenUSDValue[address] = value.price
    })
  }

  // fix later

  return {
    '0x0230718C6Cab682F420aA5E6463EA51219E72078': '26.798',
    '0x0EC52Cbf170D4351ea7a96941976eAf20B966818': '1',
  }

  // return commonTokenUSDValue
}

export function getFarmsPrices(
  farms: FarmV3Data[],
  cakePriceUSD: string,
  commonPrice: CommonPrice,
): FarmV3DataWithPrice[] {
  const commonPriceFarms = farms.map((farm) => {
    let tokenPriceBusd = BIG_ZERO
    let quoteTokenPriceBusd = BIG_ZERO

    // try to get price via common price
    if (commonPrice[farm.quoteToken.address]) {
      quoteTokenPriceBusd = new BN(commonPrice[farm.quoteToken.address])
    }
    if (commonPrice[farm.token.address]) {
      tokenPriceBusd = new BN(commonPrice[farm.token.address])
    }

    // try price via CAKE
    if (
      tokenPriceBusd.isZero() &&
      farm.token.chainId in CAKE &&
      farm.token.equals(CAKE[farm.token.chainId as keyof typeof CAKE])
    ) {
      tokenPriceBusd = new BN(cakePriceUSD)
    }
    if (
      quoteTokenPriceBusd.isZero() &&
      farm.quoteToken.chainId in CAKE &&
      farm.quoteToken.equals(CAKE[farm.quoteToken.chainId as keyof typeof CAKE])
    ) {
      quoteTokenPriceBusd = new BN(cakePriceUSD)
    }

    // try to get price via token price vs quote
    if (tokenPriceBusd.isZero() && !quoteTokenPriceBusd.isZero() && farm.tokenPriceVsQuote) {
      tokenPriceBusd = quoteTokenPriceBusd.times(farm.tokenPriceVsQuote)
    }
    if (quoteTokenPriceBusd.isZero() && !tokenPriceBusd.isZero() && farm.tokenPriceVsQuote) {
      quoteTokenPriceBusd = tokenPriceBusd.div(farm.tokenPriceVsQuote)
    }

    return {
      ...farm,
      tokenPriceBusd,
      quoteTokenPriceBusd,
    }
  })

  return commonPriceFarms.map((farm) => {
    let { tokenPriceBusd, quoteTokenPriceBusd } = farm
    // if token price is zero, try to get price from existing farms
    if (tokenPriceBusd.isZero()) {
      const ifTokenPriceFound = commonPriceFarms.find(
        (f) =>
          (farm.token.equals(f.token) && !f.tokenPriceBusd.isZero()) ||
          (farm.token.equals(f.quoteToken) && !f.quoteTokenPriceBusd.isZero()),
      )
      if (ifTokenPriceFound) {
        tokenPriceBusd = farm.token.equals(ifTokenPriceFound.token)
          ? ifTokenPriceFound.tokenPriceBusd
          : ifTokenPriceFound.quoteTokenPriceBusd
      }
      if (quoteTokenPriceBusd.isZero()) {
        const ifQuoteTokenPriceFound = commonPriceFarms.find(
          (f) =>
            (farm.quoteToken.equals(f.token) && !f.tokenPriceBusd.isZero()) ||
            (farm.quoteToken.equals(f.quoteToken) && !f.quoteTokenPriceBusd.isZero()),
        )
        if (ifQuoteTokenPriceFound) {
          quoteTokenPriceBusd = farm.quoteToken.equals(ifQuoteTokenPriceFound.token)
            ? ifQuoteTokenPriceFound.tokenPriceBusd
            : ifQuoteTokenPriceFound.quoteTokenPriceBusd
        }

        // try to get price via token price vs quote
        if (tokenPriceBusd.isZero() && !quoteTokenPriceBusd.isZero() && farm.tokenPriceVsQuote) {
          tokenPriceBusd = quoteTokenPriceBusd.times(farm.tokenPriceVsQuote)
        }
        if (quoteTokenPriceBusd.isZero() && !tokenPriceBusd.isZero() && farm.tokenPriceVsQuote) {
          quoteTokenPriceBusd = tokenPriceBusd.div(farm.tokenPriceVsQuote)
        }

        if (tokenPriceBusd.isZero()) {
          console.error(`Can't get price for ${farm.token.address}`)
        }
        if (quoteTokenPriceBusd.isZero()) {
          console.error(`Can't get price for ${farm.quoteToken.address}`)
        }
      }
    }

    return {
      ...farm,
      tokenPriceBusd: tokenPriceBusd.toString(),
      // adjust the quote token price by the token price vs quote
      quoteTokenPriceBusd:
        !quoteTokenPriceBusd.isZero() && farm.tokenPriceVsQuote
          ? tokenPriceBusd.div(farm.tokenPriceVsQuote).toString()
          : quoteTokenPriceBusd.toString(),
    }
  })
}
