/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button } from "@pancakeswap/uikit";
import { useIsMounted } from "@pancakeswap/hooks";
import { AtomBox } from "@pancakeswap/ui/components/AtomBox";
import cx from "classnames";
import { AnimatePresence as FramerAnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useMedia } from "react-use";
import styled from "styled-components";
import { Box } from "../../components/Box";
import Flex from "../../components/Box/Flex";
import CakePrice from "../../components/CakePrice/CakePrice";
import ExternalLink from "../../components/ExternalLink/ExternalLink";
import MenuItems from "../../components/MenuItems/MenuItems";
import { useMatchBreakpoints } from "../../contexts";
import Logo from "./components/Logo";
import { MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from "./config";
import { MenuContext } from "./context";
import "./index.css";
import { NavProps } from "./types";

// Fix framer-motion old React FC type (solved in react 18)
const AnimatePresence = (
  props: React.ComponentProps<typeof FramerAnimatePresence> & {
    children: ReactNode;
  }
) => <FramerAnimatePresence {...props} />;

const slideVariants = {
  hidden: { x: "-100%" },
  visible: { x: 0 },
};

const Wrapper = styled.div`
  /* padding-bottom: 20px; */
  .btn-launch {
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
    background: #2e81ff;
    border-radius: 12px;
    height: 40px;
    color: #ffffff;
    padding: 8px 16px;
    font-weight: 500;
    border: none;
    display: flex;
    align-items: center;
    width: fit-content;
    box-shadow: none;
    /* &:hover {
      transform: translateY(3px);
    } */
  }
  position: relative;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-x: hidden;
  overflow-y: auto;
  background: url(/images/bg_main.svg);
  background-size: cover;
  background-repeat: no-repeat;
`;

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${MENU_HEIGHT}px;
  transform: translate3d(0, 0, 0);

  padding-left: 24px;
  padding-right: 24px;
`;

const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  position: fixed;
  top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  left: 0;
  transition: top 0.2s;
  height: ${({ height }) => `${height}px`};
  width: 100%;
  z-index: 20;
  background-color: transparent;

  &.site-header--active {
    background-color: #000000;
  }
`;

const FooterWrapper = styled.div`
  margin-top: auto;

  width: 100%;

  display: flex;

  justify-content: space-between;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    .bottom {
      display: flex;
      flex-direction: column;
      .img-logo {
        margin-bottom: 24px;
      }
    }
  }

  padding: 24px;
  gap: 16px;
  z-index: 19;
  border-top: 1px solid #ffffff1a;

  .top {
    display: flex;
    gap: 33px;
  }

  .bottom {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    .img-logo {
      margin-right: 16px;
      width: 119px;
    }
    color: rgba(255, 255, 255, 0.6);

    @media screen and (max-width: 600px) {
      font-size: 12px;
      .img-logo {
        margin-bottom: 16px;
      }
    }
  }
`;

const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
  max-width: 100vw;
  margin: 108px 0 120px;
  height: fit-content;

  &.swap-body {
    margin-bottom: 170px !important;
  }

  /* @media (max-width: 1023px) {
    margin-top: 108px;
  } */

  @media (max-width: 767px) {
    margin-top: 100px;
  }
`;
const BodyWrapper1 = styled(Box)`
  position: relative;
  display: flex;
  max-width: 100vw;

  min-height: 100vh;
  height: fit-content;

  &.swap-body {
    margin-bottom: 170px !important;
  }

  /* @media (max-width: 1023px) {
    margin-top: 108px;
  } */

  /* @media (max-width: 767px) {
    margin-top: 100px;
  } */
`;
const Inner = styled.div`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
`;

const WrapperMobile = styled.div`
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  font-size: 14px;
  font-weight: 400;
  color: #a0a3c4;

  .soon {
    width: fit-content;
    cursor: not-allowed;
    position: relative;
    &::after {
      content: "Soon";
      position: absolute;
      top: 0;
      left: calc(100% + 8px);
      background-color: #64ab2c;
      font-weight: 400;
      font-size: 10px;
      line-height: 140%;
      padding: 0 4px;
      border-radius: 999px;
      color: black;
      /* transform: rotate(8deg); */
    }
  }
`;

const Menu: React.FC<React.PropsWithChildren<NavProps>> = ({
  linkComponent = "a",
  banner,
  rightSide,
  isDark,
  toggleTheme,
  currentLang,
  setLang,
  cakePriceUsd,
  links,
  subLinks,
  footerLinks,
  activeItem,
  activeSubItem,
  langs,
  buyCakeLabel,
  buyCakeLink,
  children,
}) => {
  const { isMobile } = useMatchBreakpoints();
  const isMounted = useIsMounted();
  const [showMenu, setShowMenu] = useState(true);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const refPrevOffset = useRef(typeof window === "undefined" ? 0 : window.pageYOffset);

  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT;

  const totalTopMenuHeight = isMounted && banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT;

  // handle header bg
  const [addBg, setAddBg] = useState(false);

  useEffect(() => {
    const header = document.querySelector(".body-scroll") as any;

    header.addEventListener("scroll", () => {
      setAddBg(header.scrollTop > 50);
    });
  }, []);
  // handle header bg

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === "Home");

  const subLinksWithoutMobile = subLinks?.filter((subLink) => !subLink.isMobileOnly);
  const subLinksMobileOnly = subLinks?.filter((subLink) => subLink.isMobileOnly);
  const providerValue = useMemo(() => ({ linkComponent }), [linkComponent]);

  const router = useRouter();
  const [active, setActive] = useState("");
  const [path, setPath] = useState<string | undefined>();
  const year = new Date().getFullYear();
  useEffect(() => {
    const arrayRouter = document.location.href.split("/") as any;
    const { length } = arrayRouter;

    setActive(arrayRouter[length - 1]);
    setPath(document.location.href);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.location.href]);

  const handleClickMenu = (link: string) => {
    router.push(link);
  };

  const isIpad = useMedia("(max-width: 768px)");
  if (router?.pathname === "/")
    return (
      <MenuContext.Provider value={providerValue}>
        <AtomBox
          asChild
          minHeight={{
            xs: "auto",
            md: "100vh",
          }}
        >
          <Wrapper className="body-scroll">
            <FixedContainer className={`header-app ${addBg && "site-header--active"}`} showMenu={showMenu} height={68}>
              <StyledNav>
                <Flex>
                  {isIpad && (
                    <div>
                      <header
                        style={{
                          marginTop: 8,
                        }}
                      >
                        <div
                          className={cx("App-header", "small", {
                            active: isDrawerVisible,
                          })}
                        >
                          <div
                            className={cx("App-header-link-container", "App-header-top", {
                              active: isDrawerVisible,
                            })}
                          >
                            {/* <div className="App-header-container-left">
                              <div
                                className="App-header-menu-icon-block"
                                onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                              >
                                <img src="/images/menu_mobile.svg" className="App-header-menu-icon" alt="menu_mobile" />
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </header>
                      {/* <AnimatePresence>
                        {isDrawerVisible && (
                          <motion.div
                            onClick={() => setIsDrawerVisible(false)}
                            className="App-header-links-container App-header-drawer"
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            variants={slideVariants}
                            transition={{ duration: 0.2 }}
                          >
                            <img
                              style={{
                                position: "absolute",
                                top: 16,
                                right: 16,
                              }}
                              src="/images/close_icon.svg"
                              alt="close_icon"
                            />

                            <WrapperMobile>
                              <Logo href={homeLink?.href ?? "/"} />

                              <div
                                className={active === "swap" ? "active btn-launch" : "btn-launch"}
                                onClick={() => handleClickMenu("/swap")}
                              >
                                Launch App
                              </div>
                            </WrapperMobile>
                          </motion.div>
                        )}
                      </AnimatePresence> */}
                    </div>
                  )}

                  <Logo href={homeLink?.href ?? "/"} />
                </Flex>

                {/* <Flex
                  style={{
                    position: "absolute",
                    top: 8,
                    left: "50%",
                    transform: "translate(-50%, 0)",
                  }}
                >
                  <AtomBox display={{ xs: "none", md: "block" }}>
                    <MenuItems
                      items={links}
                      activeItem={activeItem}
                      activeSubItem={activeSubItem}
                      active={active}
                      handleClickMenu={handleClickMenu}
                      path={path}
                    />
                  </AtomBox>
                </Flex> */}

                <Flex alignItems="center" height="100%">
                  <AtomBox mr="8px" display={{ xs: "none", lg: "block" }}>
                    <CakePrice showSkeleton={false} cakePriceUsd={cakePriceUsd} />
                  </AtomBox>
                  {/* <PriceBox>
                    <img alt="token" src="/images/token.svg" />
                    <div className="value">$2.864</div>
                  </PriceBox> */}
                  <Button
                    className="btn-launch"
                    onClick={() => {
                      handleClickMenu("/swap");
                    }}
                  >
                    Launch App
                  </Button>
                </Flex>
              </StyledNav>
            </FixedContainer>

            <BodyWrapper1 className={`${active === "" || active.startsWith("?") ? "" : ""}`}>
              <Inner>{children}</Inner>
            </BodyWrapper1>
          </Wrapper>
        </AtomBox>
      </MenuContext.Provider>
    );

  return (
    <MenuContext.Provider value={providerValue}>
      <AtomBox
        asChild
        minHeight={{
          xs: "auto",
          md: "100vh",
        }}
      >
        <Wrapper className="body-scroll">
          <FixedContainer className={`header-app ${addBg && "site-header--active"}`} showMenu={showMenu} height={68}>
            <StyledNav>
              <Flex>
                {isIpad && (
                  <div>
                    <header
                      style={{
                        marginTop: 8,
                      }}
                    >
                      <div
                        className={cx("App-header", "small", {
                          active: isDrawerVisible,
                        })}
                      >
                        <div
                          className={cx("App-header-link-container", "App-header-top", {
                            active: isDrawerVisible,
                          })}
                        >
                          <div className="App-header-container-left">
                            <div
                              className="App-header-menu-icon-block"
                              onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                            >
                              <img src="/images/menu_mobile.svg" className="App-header-menu-icon" alt="menu_mobile" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </header>

                    <AnimatePresence>
                      {isDrawerVisible && (
                        <motion.div
                          onClick={() => setIsDrawerVisible(false)}
                          className="App-header-links-container App-header-drawer"
                          initial="hidden"
                          animate="visible"
                          exit="hidden"
                          variants={slideVariants}
                          transition={{ duration: 0.2 }}
                        >
                          <img
                            style={{
                              position: "absolute",
                              top: 16,
                              right: 16,
                            }}
                            src="/images/close_icon.svg"
                            alt="close_icon"
                          />

                          <WrapperMobile>
                            <Logo href={homeLink?.href ?? "/"} />

                            <div className={active === "swap" ? "active" : ""} onClick={() => handleClickMenu("/swap")}>
                              Swap
                            </div>
                            <div
                              className={active === "liquidity" ? "active" : ""}
                              onClick={() => handleClickMenu("/liquidity")}
                            >
                              Liquidity
                            </div>
                            <div onClick={() => handleClickMenu("https://incentives.skeleton.finance/")}>
                              Incentives
                            </div>
                            {/* TODO: CHANGE LINK */}
                            <div
                              className={`${active === "farms" ? "active" : ""} soon`}
                              // onClick={() => handleClickMenu("/farms")}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                            >
                              Farms
                            </div>
                            <div
                              className={`${active === "nfts" ? "active" : ""} soon`}
                              // onClick={() => handleClickMenu("/nfts")}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                            >
                              NFT
                            </div>
                            <div
                              className={`${active === "leaderboard" ? "active" : ""}`}
                              onClick={() => handleClickMenu("/leaderboard")}
                              // onClick={(e) => {
                              //   e.stopPropagation();
                              //   e.preventDefault();
                              // }}
                            >
                              Leaderboard
                            </div>
                            <div className={active === "info" ? "active" : ""} onClick={() => handleClickMenu("/info")}>
                              Info
                            </div>
                            <div onClick={() => handleClickMenu("https://doc.skeleton.finance/")}>Docs</div>
                          </WrapperMobile>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                )}

                <Logo href={homeLink?.href ?? "/"} />
              </Flex>

              <Flex
                style={{
                  position: "absolute",
                  top: 8,
                  left: "50%",
                  transform: "translate(-50%, 0)",
                }}
              >
                <AtomBox display={{ xs: "none", md: "block" }}>
                  <MenuItems
                    items={links}
                    activeItem={activeItem}
                    activeSubItem={activeSubItem}
                    active={active}
                    handleClickMenu={handleClickMenu}
                    path={path}
                  />
                </AtomBox>
              </Flex>

              <Flex alignItems="center" height="100%">
                <AtomBox mr="8px" display={{ xs: "none", lg: "block" }}>
                  <CakePrice showSkeleton={false} cakePriceUsd={cakePriceUsd} />
                </AtomBox>
                {rightSide}
              </Flex>
            </StyledNav>
          </FixedContainer>
          {/* {subLinks ? (
            <Flex justifyContent="space-around" overflow="hidden">
              <SubMenuItems
                items={subLinksWithoutMobile}
                mt={`${totalTopMenuHeight + 1}px`}
                activeItem={activeSubItem}
              />

              {subLinksMobileOnly && subLinksMobileOnly?.length > 0 && (
                <SubMenuItems
                  items={subLinksMobileOnly}
                  mt={`${totalTopMenuHeight + 1}px`}
                  activeItem={activeSubItem}
                  isMobileOnly
                />
              )}
            </Flex>
          ) : (
            <div />
          )} */}
          <BodyWrapper className={`${active === "" || active.startsWith("?") ? "swap-body" : ""}`} mt={102}>
            <Inner>{children}</Inner>
          </BodyWrapper>

          <FooterWrapper>
            {" "}
            <div className="bottom">
              {" "}
              <a href="https://www.mummy.finance/#/" target="_self">
                <img alt="mmy" className="img-logo" src="/images/home/landing/mmy-logo.svg" />
              </a>
              Copyright © {year} Skeleton Finance. All rights reserved.
            </div>
            <div className="top">
              <ExternalLink href="https://discord.gg/xqSJaw8sUB">
                <img src="/images/footer/discord.svg" alt="discord" />
              </ExternalLink>
              <ExternalLink href="https://medium.com/@mummyftm">
                <img src="/images/footer/medium.svg" alt="medium" />
              </ExternalLink>
              <ExternalLink href="https://t.me/mummyftm">
                <img src="/images/footer/telegram.svg" alt="telegram" />
              </ExternalLink>
              <ExternalLink href="https://twitter.com/mummyftm">
                <img src="/images/footer/twitter.svg" alt="twitter" />
              </ExternalLink>
              <ExternalLink href="https://linktr.ee/mummyfiftm">
                <img src="/images/footer/linktree.svg" alt="linktree" />
              </ExternalLink>
            </div>
          </FooterWrapper>
        </Wrapper>
      </AtomBox>
    </MenuContext.Provider>
  );
};
const PriceBox = styled.div`
  height: 40px;
  margin-right: 8px;
  display: flex;
  align-items: center;

  background: #010815;
  padding: 4px 12px;

  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  img {
    width: 24px;
    margin-right: 7px;
  }
  .value {
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: #fff;
  }
  @media screen and (max-width: 700px) {
    display: none;
  }
`;
export default Menu;
