import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.84322 15.7427C6.4527 16.1333 6.4527 16.7664 6.84322 17.1569C7.23374 17.5475 7.86691 17.5475 8.25743 17.1569L16.4498 8.96455L16.4498 15.0356C16.4498 15.5879 16.8975 16.0356 17.4498 16.0356C18.0021 16.0356 18.4498 15.5879 18.4498 15.0356L18.4498 6.55034C18.4498 6.28512 18.3445 6.03077 18.1569 5.84323C17.9694 5.6557 17.715 5.55034 17.4498 5.55034L8.96454 5.55034C8.41226 5.55034 7.96454 5.99806 7.96454 6.55034C7.96454 7.10262 8.41226 7.55034 8.96454 7.55034L15.0356 7.55034L6.84322 15.7427Z"
        fill="#2E81FF"
      />
    </Svg>
  );
};

export default Icon;
