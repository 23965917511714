import styled from 'styled-components'
import { Box, Button, Flex, Text } from '@pancakeswap/uikit'
import { useAppDispatch } from 'state'
import { useAllSortedRecentTransactions } from 'state/transactions/hooks'
import { useTranslation } from '@pancakeswap/localization'
import { clearAllTransactions } from 'state/transactions/actions'
import isEmpty from 'lodash/isEmpty'
import TransactionRow from './TransactionRow'
import { chains } from '../../../utils/wagmi'

const TransactionsContainer = styled(Box)`
  max-height: 300px;
  min-height: 84px;
  overflow-y: auto;
`

const ClearButton = styled(Text)`
  transition: 400ms;
  cursor: pointer;
  padding: 4px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #2e81ff;
  background-color: #4f90ff1a;
  border-radius: 999px;

  &:hover {
    opacity: 0.65;
  }
`

interface WalletTransactionsProps {
  onDismiss: () => void
}

const WalletTransactions: React.FC<React.PropsWithChildren<WalletTransactionsProps>> = ({ onDismiss }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const sortedTransactions = useAllSortedRecentTransactions()

  const hasTransactions = !isEmpty(sortedTransactions)

  const handleClearAll = () => {
    dispatch(clearAllTransactions())
  }

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between" mb="24px">
        <Text fontSize="14px" textTransform="uppercase" fontWeight="400" lineHeight="140%" color="#2E81FF">
          {t('Recent Transactions')}
        </Text>
        {hasTransactions && (
          <ClearButton onClick={handleClearAll} fontSize="14px" fontWeight="400" lineHeight="140%" color="#2E81FF">
            {t('Clear all')}
          </ClearButton>
        )}
      </Flex>
      {hasTransactions ? (
        <TransactionsContainer>
          {Object.entries(sortedTransactions).map(([chainId, transactions]) => {
            const chainIdNumber = Number(chainId)
            return (
              <Box key={chainId}>
                {/* <Text fontSize="12px" color="textSubtle" mb="4px">
                  {chains.find((c) => c.id === chainIdNumber)?.name ?? 'Unknown network'}
                </Text> */}
                {Object.values(transactions).map((txn) => (
                  <TransactionRow
                    key={txn.hash}
                    txn={txn}
                    chainId={chainIdNumber}
                    type={txn.type}
                    onDismiss={onDismiss}
                  />
                ))}
              </Box>
            )
          })}
        </TransactionsContainer>
      ) : (
        <Text textAlign="center" fontWeight="500" fontSize="14px" lineHeight="140%" color="#FFFFFF99" py="16px">
          {t('No recent transactions')}
        </Text>
      )}
    </Box>
  )
}

export default WalletTransactions
