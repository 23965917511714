import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9998 6.99992C13.368 6.99992 13.6665 7.2984 13.6665 7.66658C13.6665 8.03477 13.368 8.33325 12.9998 8.33325L5.27598 8.33325L8.13791 11.1952C8.39826 11.4555 8.39826 11.8776 8.13791 12.138C7.87756 12.3983 7.45545 12.3983 7.1951 12.138L3.1951 8.13799C3.07007 8.01297 2.99984 7.8434 2.99984 7.66659C2.99984 7.48977 3.07007 7.32021 3.1951 7.19518L7.1951 3.19518C7.45545 2.93483 7.87756 2.93483 8.13791 3.19518C8.39826 3.45553 8.39826 3.87764 8.13791 4.13799L5.27598 6.99992L12.9998 6.99992Z"
        fill="white"
        // fillOpacity="0.6"
      />
    </svg>
  );
};

export default Icon;
