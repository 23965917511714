import { parseUnits } from '@ethersproject/units'
import { BigNumber } from 'ethers'
import {
  ButtonMenu,
  ButtonMenuItem,
  CloseIcon,
  Heading,
  IconButton,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalHeader as UIKitModalHeader,
  ModalTitle,
} from '@pancakeswap/uikit'
import { useAccount, useBalance } from 'wagmi'
import { useState, useCallback } from 'react'
import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'
import WalletInfo from './WalletInfo'
import WalletTransactions from './WalletTransactions'
import WalletWrongNetwork from './WalletWrongNetwork'

export enum WalletView {
  WALLET_INFO,
  TRANSACTIONS,
  WRONG_NETWORK,
}

interface WalletModalProps extends InjectedModalProps {
  initialView?: WalletView
}

export const LOW_NATIVE_BALANCE = parseUnits('0.002', 'ether')

const StyledModalContainer = styled(ModalContainer)`
  @media (max-width: 767px) {
    min-width: unset !important;
  }
`

const ModalHeader = styled(UIKitModalHeader)`
  background: #1b2b45;
`

const Tabs = styled.div`
  background-color: #1b2b45;
  padding: 16px 16px 0;
`

const StyledButtonMenu = styled(ButtonMenu)`
  background-color: #162237;
`

const StyledButtonMenuItem = styled(ButtonMenuItem)`
  height: 36px;
  border-radius: 16px;
`

interface TabsComponentProps {
  view: WalletView
  handleClick: (newIndex: number) => void
}

const TabsComponent: React.FC<React.PropsWithChildren<TabsComponentProps>> = ({ view, handleClick }) => {
  const { t } = useTranslation()

  return (
    <Tabs>
      <StyledButtonMenu scale="sm" variant="subtle" onItemClick={handleClick} activeIndex={view} fullWidth>
        <StyledButtonMenuItem>{t('Wallet')}</StyledButtonMenuItem>
        <StyledButtonMenuItem>{t('Transactions')}</StyledButtonMenuItem>
      </StyledButtonMenu>
    </Tabs>
  )
}

const WalletModal: React.FC<React.PropsWithChildren<WalletModalProps>> = ({
  initialView = WalletView.WALLET_INFO,
  onDismiss,
}) => {
  const [view, setView] = useState(initialView)
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { data, isFetched } = useBalance({ address: account })
  const hasLowNativeBalance =
    isFetched && data && BigNumber.isBigNumber(data.value) && data.value.lte(LOW_NATIVE_BALANCE)

  const handleClick = useCallback((newIndex: number) => {
    setView(newIndex)
  }, [])

  return (
    <StyledModalContainer minWidth="424px">
      <ModalHeader>
        <ModalTitle>
          <Heading>{t('Your Wallet')}</Heading>
        </ModalTitle>
        <IconButton
          width="16px !important"
          height="16px"
          p="0 !important"
          variant="text"
          onClick={onDismiss}
          aria-label="Close the dialog"
        >
          <CloseIcon width="16px" height="16px" color="#FFFFFF99" />
        </IconButton>
      </ModalHeader>
      {view !== WalletView.WRONG_NETWORK && <TabsComponent view={view} handleClick={handleClick} />}
      <ModalBody p="24px 16px 16px 16px !important" width="100%">
        {view === WalletView.WALLET_INFO && (
          <WalletInfo hasLowNativeBalance={hasLowNativeBalance} switchView={handleClick} onDismiss={onDismiss} />
        )}
        {view === WalletView.TRANSACTIONS && <WalletTransactions onDismiss={onDismiss} />}
        {view === WalletView.WRONG_NETWORK && <WalletWrongNetwork onDismiss={onDismiss} />}
      </ModalBody>
    </StyledModalContainer>
  )
}

export default WalletModal
