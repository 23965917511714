import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Text } from "../Text";
import { IconButton } from "../Button";
import { CloseIcon, TxFailedIcon, TxInfoIcon, TxSuccessIcon, TxWarningIcon } from "../Svg";
import Flex from "../Box/Flex";
import { AlertProps, variants } from "./types";

interface ThemedIconLabel {
  variant: AlertProps["variant"];
  theme: DefaultTheme;
  hasDescription: boolean;
}

const getThemeColor = ({ theme, variant = variants.INFO }: ThemedIconLabel) => {
  switch (variant) {
    case variants.DANGER:
      return "#E14337";
    case variants.WARNING:
      return "#F9AD2A";
    case variants.SUCCESS:
      return "#64AB2C";
    case variants.INFO:
    default:
      return "#2E81FF";
  }
};

const getIcon = (variant: AlertProps["variant"] = variants.INFO) => {
  switch (variant) {
    case variants.DANGER:
      return TxFailedIcon;
    case variants.WARNING:
      return TxWarningIcon;
    case variants.SUCCESS:
      return TxSuccessIcon;
    case variants.INFO:
    default:
      return TxInfoIcon;
  }
};

const IconLabel = styled.div<ThemedIconLabel>`
  background-color: transparent;
  color: ${getThemeColor};
  padding: 24px;
  padding-right: 8px;
`;

const withHandlerSpacing = 32 + 12 + 8; // button size + inner spacing + handler position
const Details = styled.div<{ hasHandler: boolean }>`
  flex: 1;
  padding-bottom: 24px;
  padding-right: ${({ hasHandler }) => (hasHandler ? `${withHandlerSpacing}px` : "24px")};
  padding-top: 24px;
  background-color: transparent;
`;

const CloseHandler = styled.div`
  border-radius: 0 16px 16px 0;
  right: 8px;
  position: absolute;
  top: 8px;
`;

const DescriptionWrapper = styled.div`
  margin-top: 4px;
  > * {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #fff;
  }
`;

const StyledAlert = styled(Flex)<ThemedIconLabel>`
  position: relative;
  background-color: #151c27;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid ${getThemeColor};
`;

const Alert: React.FC<React.PropsWithChildren<AlertProps>> = ({ title, children, variant, onClick }) => {
  const Icon = getIcon(variant);

  return (
    <StyledAlert variant={variant} hasDescription={!!children}>
      <IconLabel variant={variant} hasDescription={!!children}>
        <Icon color="currentColor" width="24px" height="24px" />
      </IconLabel>
      <Details hasHandler={!!onClick}>
        <Text fontWeight="700" fontSize="16px" lineHeight="140%" color="#fff">
          {title}
        </Text>
        {typeof children === "string" ? (
          <Text
            fontWeight="400"
            fontSize="14px"
            lineHeight="140%"
            color="#fff"
            style={{ wordBreak: "break-all" }}
            as="p"
            mt="4px"
          >
            {children}
          </Text>
        ) : (
          <DescriptionWrapper>{children}</DescriptionWrapper>
        )}
      </Details>
      {onClick && (
        <CloseHandler>
          <IconButton scale="sm" variant="text" onClick={onClick}>
            <CloseIcon width="16px" color="#FFFFFF99" />
          </IconButton>
        </CloseHandler>
      )}
    </StyledAlert>
  );
};

export default Alert;
