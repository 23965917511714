import React from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const Loader = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  border-top: 2px solid rgba(46, 129, 255, 1);
  border-right: 2px solid transparent;
  box-sizing: border-box;
  animation: ${rotate} 1s linear infinite;
  margin-left: auto;
`

const CircleLoader = () => {
  return <Loader />
}

export default CircleLoader
