import styled from "styled-components";
import { PolymorphicComponent } from "../../util/polymorphic";
import Button from "../Button/Button";
import { BaseButtonProps, variants } from "../Button/types";
import { ButtonMenuItemProps } from "./types";

interface InactiveButtonProps extends BaseButtonProps {
  forwardedAs: BaseButtonProps["as"];
}

const InactiveButton: PolymorphicComponent<InactiveButtonProps, "button"> = styled(Button)<InactiveButtonProps>`
  background-color: transparent !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  padding: 8px 16px;
  color: rgba(255, 255, 255, 0.6);
  height: 100%;
  &:hover:not(:disabled):not(:active) {
    background-color: transparent;
  }
`;

const ActiveButton = styled(Button)`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  padding: 8px 16px;
  color: #ffffff;
  border-radius: 24px;

  height: 100%;
  background-color: #2e81ff;
`;

const ButtonMenuItem: PolymorphicComponent<ButtonMenuItemProps, "button"> = ({
  isActive = false,
  variant = variants.PRIMARY,
  as,
  ...props
}: ButtonMenuItemProps) => {
  if (!isActive) {
    return <InactiveButton forwardedAs={as} variant={variant} {...props} />;
  }

  return <ActiveButton as={as} variant={variant} {...props} />;
};

export default ButtonMenuItem;
