/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { memo } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 8px;
  gap: 12px;

  height: 52px;

  background: #010815;
  border-radius: 100px;

  .soon {
    cursor: not-allowed;
    position: relative;
    &::after {
      content: "Soon";
      position: absolute;
      top: 0;
      right: 0;
      background-color: #64ab2c;
      font-weight: 400;
      font-size: 10px;
      line-height: 140%;
      padding: 0 4px;
      border-radius: 999px;
      color: black;
      transform: rotate(8deg);
    }
  }

  div {
    cursor: pointer;

    padding: 8px 16px;

    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    border-radius: 100px;
    color: rgba(255, 255, 255, 0.6);

    &.active {
      background: #2e81ff;
      color: #ffffff;
    }

    &:not(.active):hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`;

function isActiveMenuItem(path: string, namePath: string[]) {
  return path && namePath.some((name) => path.includes(name)) ? "active" : "";
}

const LIQUIDITY_PATH = ["liquidity", "increase", "remove", "add"];

const MenuItems: React.FC<React.PropsWithChildren<any>> = ({ active, handleClickMenu, path }) => {
  return (
    <Wrapper>
      <div className={isActiveMenuItem(path, ["swap"])} onClick={() => handleClickMenu("/swap")}>
        Swap
      </div>
      <div className={isActiveMenuItem(path, LIQUIDITY_PATH)} onClick={() => handleClickMenu("/liquidity")}>
        Liquidity
      </div>
      <div onClick={() => window.open("https://incentives.skeleton.finance/", "_blank")}>Incentives</div>
      {/* TODO: CHANGE LINK */}
      <div
        className={`${isActiveMenuItem(path, ["farms"])} soon`}
        // onClick={() => handleClickMenu("/farms")}
      >
        Farms
      </div>
      <div
        className={`${active === "nfts" ? "active" : ""} soon`}
        // onClick={() => handleClickMenu("/nfts")}
      >
        NFT
      </div>
      <div className={`${active === "leaderboard" ? "active" : ""}`} onClick={() => handleClickMenu("/leaderboard")}>
        Leaderboard
      </div>
      <div className={path?.includes("/info") ? "active" : ""} onClick={() => handleClickMenu("/info")}>
        Info
      </div>
      <div onClick={() => window.open("https://doc.skeleton.finance/", "_blank")}>Docs</div>
    </Wrapper>
  );
};

export default memo(MenuItems);
