/* eslint-disable @typescript-eslint/no-extra-semi */
import { ModalV2 } from '@pancakeswap/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { CHAIN_IDS } from 'utils/wagmi'
import { ChainId } from '@pancakeswap/sdk'
import { useEffect, useMemo } from 'react'
import { useNetwork } from 'wagmi'
import { atom, useAtom } from 'jotai'
import { SUPPORT_CHAINS } from 'config/constants/supportChains'
import dynamic from 'next/dynamic'
import { useSwitchNetwork, useSwitchNetworkLocal } from 'hooks/useSwitchNetwork'
import { useRouter } from 'next/router'

export const hideWrongNetworkModalAtom = atom(false)

const PageNetworkSupportModal = dynamic(
  () => import('./PageNetworkSupportModal').then((mod) => mod.PageNetworkSupportModal),
  { ssr: false },
)
const WrongNetworkModal = dynamic(() => import('./WrongNetworkModal').then((mod) => mod.WrongNetworkModal), {
  ssr: false,
})
const UnsupportedNetworkModal = dynamic(
  () => import('./UnsupportedNetworkModal').then((mod) => mod.UnsupportedNetworkModal),
  { ssr: false },
)

export const NetworkModal = ({ pageSupportedChains = SUPPORT_CHAINS }: { pageSupportedChains?: number[] }) => {
  const { chainId, chain, isWrongNetwork, account } = useActiveWeb3React()
  const { chains } = useNetwork()
  const [dismissWrongNetwork, setDismissWrongNetwork] = useAtom(hideWrongNetworkModalAtom)

  // const isBNBOnlyPage = useMemo(() => {
  //   return pageSupportedChains?.length === 1 && pageSupportedChains[0] === ChainId.FANTOM
  // }, [pageSupportedChains])

  const isPageNotSupported = useMemo(
    () => Boolean(pageSupportedChains.length) && !pageSupportedChains.includes(chainId),
    [chainId, pageSupportedChains],
  )

  // if (isPageNotSupported && isBNBOnlyPage) {
  //   return (
  //     <ModalV2 isOpen closeOnOverlayClick={false}>
  //       <PageNetworkSupportModal />
  //     </ModalV2>
  //   )
  // }

  const router = useRouter()

  if ((isPageNotSupported || (isWrongNetwork && !dismissWrongNetwork)) && router.pathname !== '/') {
    return (
      <ModalV2 isOpen closeOnOverlayClick={false}>
        <UnsupportedNetworkModal pageSupportedChains={pageSupportedChains?.length ? pageSupportedChains : CHAIN_IDS} />
      </ModalV2>
    )
  }

  // if (isWrongNetwork && !dismissWrongNetwork) {
  //   const currentChain = chains.find((c) => c.id === chainId)
  //   if (!currentChain) return null
  //   return (
  //     <ModalV2 isOpen={isWrongNetwork} closeOnOverlayClick onDismiss={() => setDismissWrongNetwork(true)}>
  //       <WrongNetworkModal currentChain={currentChain} onDismiss={() => setDismissWrongNetwork(true)} />
  //     </ModalV2>
  //   )
  // }

  return null
}
