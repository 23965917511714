import { goerliTestnetTokens, mumbaiTokens } from '@pancakeswap/tokens'
import { FarmConfigV3, SerializedFarmConfig } from '@pancakeswap/farms'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { ERC20Token } from '@pancakeswap/sdk'

const BTC_MUMBAI = new ERC20Token(
  80001,
  '0x0230718C6Cab682F420aA5E6463EA51219E72078',
  8,
  'BTC',
  'Bitcoin',
  'https://bitcoin.org/en/',
)

export const farmsV3 = [
  {
    pid: 1,
    lpSymbol: 'BTC-USDC LP',
    lpAddress: '0x191bbf1e26f775a18f9dd5c7a73255e18c3fe38d',
    token: BTC_MUMBAI,
    quoteToken: mumbaiTokens.usdc,
    feeAmount: FeeAmount.LOW,
  },
] satisfies FarmConfigV3[]

const farms: SerializedFarmConfig[] = [
  {
    pid: 34,
    vaultPid: 3,
    lpSymbol: 'CELR-WETH LP',
    lpAddress: '0xF8E1FA0648F87c115F26E43271B3D6e4a80A2944',
    quoteToken: goerliTestnetTokens.weth,
    token: goerliTestnetTokens.celr,
  },
  {
    pid: 23,
    vaultPid: 3,
    lpSymbol: 'LEET-WETH LP',
    lpAddress: '0x846f5e6DDb29dC5D07f8dE0a980E30cb5aa07109',
    quoteToken: goerliTestnetTokens.weth,
    token: goerliTestnetTokens.leet,
  },
].map((p) => ({ ...p, token: p.token.serialize, quoteToken: p.quoteToken.serialize }))

export default farms
