import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1445_30873)">
        <path
          d="M60.5 10C50.6109 10 40.944 12.9324 32.7215 18.4265C24.499 23.9206 18.0904 31.7295 14.306 40.8658C10.5217 50.0021 9.5315 60.0555 11.4608 69.7545C13.39 79.4535 18.1521 88.3627 25.1447 95.3553C32.1373 102.348 41.0464 107.11 50.7455 109.039C60.4445 110.968 70.4979 109.978 79.6342 106.194C88.7705 102.41 96.5794 96.0009 102.073 87.7785C107.568 79.556 110.5 69.889 110.5 60C110.462 46.751 105.181 34.0556 95.8128 24.6872C86.4444 15.3187 73.749 10.0385 60.5 10V10ZM81.125 74.7333L75.2333 80.625L60.5 65.8917L45.7667 80.625L39.875 74.7333L54.6083 60L39.875 45.2667L45.7667 39.375L60.5 54.1083L75.2333 39.375L81.125 45.2667L66.3917 60L81.125 74.7333Z"
          fill="#E14337"
        />
      </g>
      <defs>
        <clipPath id="clip0_1445_30873">
          <rect width="120" height="120" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
