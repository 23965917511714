import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1394 2.86201C13.3997 3.12236 13.3997 3.54447 13.1394 3.80482L3.80604 13.1382C3.54569 13.3985 3.12358 13.3985 2.86323 13.1382C2.60288 12.8778 2.60288 12.4557 2.86323 12.1953L12.1966 2.86201C12.4569 2.60166 12.879 2.60166 13.1394 2.86201Z"
        fill="white"
        fillOpacity="0.6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.86323 2.86201C3.12358 2.60166 3.54569 2.60166 3.80604 2.86201L13.1394 12.1953C13.3997 12.4557 13.3997 12.8778 13.1394 13.1382C12.879 13.3985 12.4569 13.3985 12.1966 13.1382L2.86323 3.80482C2.60288 3.54447 2.60288 3.12236 2.86323 2.86201Z"
        fill="white"
        fillOpacity="0.6"
      />
    </Svg>
  );
};

export default Icon;
