import React from "react";
import styled from "styled-components";
import NextLink from "next/link";
import LogoRound from "../Svg/Icons/LogoRound";
import Text from "../Text/Text";
import Skeleton from "../Skeleton/Skeleton";
import { Colors } from "../../theme";

export interface Props {
  color?: keyof Colors;
  cakePriceUsd?: number;
  showSkeleton?: boolean;
}

const PriceLink = styled(NextLink)`
  display: flex;
  align-items: center;

  padding: 4px 12px;
  gap: 7px;

  background: #010815;

  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;

  font-weight: 700;
  font-size: 14px;
  line-height: 140%;

  color: #ffffff;

  svg {
    transition: transform 0.3s;
  }
  :hover {
    svg {
      transform: scale(1.2);
    }
  }
`;

const CakePrice: React.FC<React.PropsWithChildren<Props>> = ({
  cakePriceUsd,
  color = "textSubtle",
  showSkeleton = true,
}) => {
  return cakePriceUsd ? (
    <PriceLink href="/" target="_blank">
      <img src={`${window.location.origin}/images/token.svg`} alt="token" />

      {`$${cakePriceUsd.toFixed(3)}`}
    </PriceLink>
  ) : showSkeleton ? (
    <Skeleton width={80} height={24} />
  ) : null;
};

export default React.memo(CakePrice);
