import React, { MouseEvent } from "react";
import styled from "styled-components";
import Flex from "../../components/Box/Flex";
import { MotionBox } from "../../components/Box";
import { ArrowBackIcon, CloseIcon } from "../../components/Svg";
import { IconButton } from "../../components/Button";
import { ModalProps } from "./types";

export const mobileFooterHeight = 73;

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  background: #1b2b45;
  border-bottom: 1px solid #ffffff1a;
  display: flex;
  padding: 16px;

  /* ${({ theme }) => theme.mediaQueries.md} {
    background: ${({ background }) => background || "transparent"};
  } */
`;

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;

  > h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #fff;
  }
`;

export const ModalBody = styled(Flex)`
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(90vh - ${mobileFooterHeight}px);
  padding: 16px !important;

  > * {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
    max-height: 90vh;
  }

  /* @media (max-width: 767px) {
    max-height: unset;
  } */

  &.modal-farm {
    padding: 16px;
  }
`;

export const ModalCloseButton: React.FC<React.PropsWithChildren<{ onDismiss: ModalProps["onDismiss"] }>> = ({
  onDismiss,
}) => {
  return (
    <IconButton
      width="16px !important"
      height="16px"
      p="0 !important"
      variant="text"
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onDismiss?.();
      }}
      aria-label="Close the dialog"
    >
      <CloseIcon width="16px" height="16px" color="#FFFFFF99" />
    </IconButton>
  );
};

export const ModalBackButton: React.FC<React.PropsWithChildren<{ onBack: ModalProps["onBack"] }>> = ({ onBack }) => {
  return (
    <IconButton
      variant="text"
      onClick={onBack}
      area-label="go back"
      mr="8px"
      width="24px !important"
      height="24px"
      p="0"
    >
      <ArrowBackIcon width="16" height="16" color="#FFFFFF99" />
    </IconButton>
  );
};

export const ModalContainer = styled(MotionBox)`
  overflow: hidden;
  background: #1b2b45;
  box-shadow: 0px 20px 36px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  width: 100%;
  max-height: calc(var(--vh, 1vh) * 100);
  z-index: ${({ theme }) => theme.zIndices.modal};
  position: absolute;
  bottom: 0;
  max-width: none !important;
  min-height: 188px;

  @media (max-width: 1023px) {
    border-radius: 16px 16px 0 0;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    width: auto;
    position: auto;
    bottom: auto;
    border-radius: 16px;
    max-height: 100vh;
  }
`;
