import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: 'Skeleton | The Premier AMM DEX on Fantom',
  defaultTitle: 'Skeleton | The Premier AMM DEX on Fantom',
  description:
    'The most efficient and user-friendly DEX native to Fantom with swapping, earning, and much more. Our AMM utilizes concentrated liquidity pools to enhance capital efficiency and minimize impermanent loss.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@Skeleton',
    site: '@Skeleton',
  },
  openGraph: {
    title: 'Skeleton | The Premier AMM DEX on Fantom',
    description:
      'The most efficient and user-friendly DEX native to Fantom with swapping, earning, and much more. Our AMM utilizes concentrated liquidity pools to enhance capital efficiency and minimize impermanent loss.',
    images: [{ url: '/images/banners/trade.png' }],
  },
}
