import { ChainId, ERC20Token } from '@pancakeswap/sdk'
import { bscTestnetTokens, bscTokens, mumbaiTokens, ethereumTokens, goerliTestnetTokens } from '@pancakeswap/tokens'
import type { CommonPrice } from '../../src/fetchFarmsV3'

export const CAKE_BNB_LP_MAINNET = '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0'

export type PriceHelper = {
  chain: string
  list: ERC20Token[]
}

export const CHAIN_ID_TO_CHAIN_NAME = {
  [ChainId.BSC]: 'bsc',
  [ChainId.ETHEREUM]: 'ethereum',
  [ChainId.GOERLI]: 'ethereum',
  [ChainId.BSC_TESTNET]: 'bsc',
  [ChainId.MUMBAI]: 'mumbai',
  [ChainId.FANTOM]: 'fantom',
} satisfies Record<ChainId, string>

const BTC_MUMBAI = new ERC20Token(
  80001,
  '0x0230718C6Cab682F420aA5E6463EA51219E72078',
  8,
  'BTC',
  'Bitcoin',
  'https://bitcoin.org/en/',
)

const BTC_FANTOM = new ERC20Token(
  250,
  '0x321162Cd933E2Be498Cd2267a90534A804051b11',
  8,
  'BTC',
  'Bitcoin',
  'https://bitcoin.org/en/',
)

const USDC_FANTOM = new ERC20Token(
  250,
  '0x28a92dde19D9989F39A49905d7C9C2FAc7799bDf',
  6,
  'lzUSDC',
  'USD Coin',
  'https://www.centre.io/',
)

export const priceHelperTokens = {
  [ChainId.ETHEREUM]: {
    chain: 'ethereum',
    list: [
      ethereumTokens.weth,
      ethereumTokens.usdc,
      ethereumTokens.usdt,
      ethereumTokens.dai,
      ethereumTokens.wbtc,
      ethereumTokens.alcx,
    ],
  },
  [ChainId.BSC]: {
    chain: 'bsc',
    list: [bscTokens.wbnb, bscTokens.usdt, bscTokens.busd, bscTokens.btcb, bscTokens.eth],
  },
  [ChainId.MUMBAI]: {
    chain: 'mumbai',
    list: [BTC_MUMBAI, mumbaiTokens.usdc],
  },
  [ChainId.FANTOM]: {
    chain: 'fantom',
    list: [BTC_FANTOM, USDC_FANTOM],
  },
} satisfies Record<number, PriceHelper>

// for testing purposes
export const DEFAULT_COMMON_PRICE: Record<ChainId, CommonPrice> = {
  [ChainId.ETHEREUM]: {},
  [ChainId.GOERLI]: {
    [goerliTestnetTokens.mockA.address]: '10',
  },
  [ChainId.BSC]: {},
  [ChainId.MUMBAI]: {},
  [ChainId.FANTOM]: {},
  [ChainId.BSC_TESTNET]: {
    [bscTestnetTokens.mockA.address]: '10',
    [bscTestnetTokens.usdt.address]: '1',
    [bscTestnetTokens.busd.address]: '1',
    [bscTestnetTokens.usdc.address]: '1',
  },
}
