import {
  BlockIcon,
  CheckmarkCircleIcon,
  Flex,
  BscScanIcon,
  RefreshIcon,
  TxSuccessIcon,
  TxFailedIcon,
  LinkExternal,
} from '@pancakeswap/uikit'
import styled from 'styled-components'
import { useAppDispatch } from 'state'
import { useTranslation } from '@pancakeswap/localization'
import { TransactionDetails } from 'state/transactions/reducer'
import { pickFarmTransactionTx } from 'state/global/actions'
import { TransactionType, FarmTransactionStatus } from 'state/transactions/actions'
import { getBlockExploreLink } from 'utils'

interface TransactionRowProps {
  txn: TransactionDetails
  chainId: number
  type: TransactionType
  onDismiss: () => void
}

const TxnIcon = styled(Flex)`
  align-items: center;
  flex: none;
  width: 24px;
`

const Summary = styled.div`
  /* flex: 1; */
  padding: 0 8px;
`

const TxnLink = styled.div`
  cursor: pointer;
  height: 20px;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  margin-bottom: 16px;
  width: 100%;

  &:hover {
    text-decoration: none;
  }
`

const renderIcon = (txn: TransactionDetails) => {
  const { receipt, nonBscFarm } = txn
  if (!txn.receipt || nonBscFarm?.status === FarmTransactionStatus.PENDING) {
    return <RefreshIcon spin width="24px" />
  }

  const isFarmStatusSuccess = nonBscFarm ? nonBscFarm.status === FarmTransactionStatus.SUCCESS : true
  return (receipt?.status === 1 && isFarmStatusSuccess) || typeof receipt?.status === 'undefined' ? (
    <TxSuccessIcon width="24px" />
  ) : (
    <TxFailedIcon width="24px" />
  )
}

const TransactionRow: React.FC<React.PropsWithChildren<TransactionRowProps>> = ({ txn, chainId, type, onDismiss }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const onClickTransaction = () => {
    if (type === 'non-bsc-farm') {
      onDismiss()
      dispatch(pickFarmTransactionTx({ tx: txn.hash, chainId }))
    } else {
      const url = getBlockExploreLink(txn.hash, 'transaction', chainId)
      window.open(url, '_blank', 'noopener noreferrer')
    }
  }

  if (!txn) {
    return null
  }

  return (
    <TxnLink onClick={onClickTransaction}>
      <TxnIcon>{renderIcon(txn)}</TxnIcon>
      <Summary>
        {txn.translatableSummary
          ? t(txn.translatableSummary.text, txn.translatableSummary.data)
          : txn.summary ?? txn.hash}
      </Summary>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.70333 13.1189C5.3779 13.4443 5.3779 13.9719 5.70333 14.2974C6.02877 14.6228 6.55641 14.6228 6.88185 14.2974L13.7088 7.47038L13.7088 12.5296C13.7088 12.9898 14.0819 13.3629 14.5422 13.3629C15.0024 13.3629 15.3755 12.9898 15.3755 12.5296L15.3755 5.45854C15.3755 5.23753 15.2877 5.02557 15.1314 4.86928C14.9751 4.71301 14.7632 4.62521 14.5422 4.62521L7.4711 4.62521C7.01086 4.62521 6.63777 4.9983 6.63777 5.45854C6.63777 5.91878 7.01086 6.29187 7.4711 6.29187L12.5303 6.29187L5.70333 13.1189Z"
          fill="white"
        />
      </svg>

      {/* <TxnIcon>
        <BscScanIcon width="24px" color="primary" />
      </TxnIcon> */}
    </TxnLink>
  )
}

export default TransactionRow
