import { Link, Text, BscScanIcon } from '@pancakeswap/uikit'
import { ChainId } from '@pancakeswap/sdk'
import { getBlockExploreLink, getBlockExploreName } from 'utils'
import { useTranslation } from '@pancakeswap/localization'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { useActiveChainId } from 'hooks/useActiveChainId'

interface DescriptionWithTxProps {
  description?: string
  txHash?: string
  txChainId?: number
}

const DescriptionWithTx: React.FC<React.PropsWithChildren<DescriptionWithTxProps>> = ({
  txHash,
  txChainId,
  children,
}) => {
  const { chainId } = useActiveChainId()
  const { t } = useTranslation()

  return (
    <>
      {typeof children === 'string' ? (
        <Text as="p" fontWeight="400" fontSize="14px" lineHeight="140%" color="#fff">
          {children}
        </Text>
      ) : (
        children
      )}
      {txHash && (
        <Link
          fontWeight="400"
          fontSize="14px"
          lineHeight="140%"
          color="#fff"
          external
          href={getBlockExploreLink(txHash, 'transaction', txChainId || chainId)}
        >
          <Text fontWeight="400" fontSize="14px" lineHeight="140%" color="#fff">
            {t('View on %site%', { site: getBlockExploreName(txChainId || chainId) })}: {truncateHash(txHash, 8, 0)}
          </Text>
          {/* {(txChainId || chainId) === ChainId.BSC && <BscScanIcon color="primary" ml="4px" />} */}
        </Link>
      )}
    </>
  )
}

export default DescriptionWithTx
