import React from "react";
import Link from "./Link";
import { LinkProps } from "./types";
import OpenNewIcon from "../Svg/Icons/OpenNew";
import BscScanIcon from "../Svg/Icons/BscScan";
import AptosIcon from "../Svg/Icons/Aptos";

const LinkExternal: React.FC<React.PropsWithChildren<LinkProps>> = ({
  children,
  isBscScan = false,
  isAptosScan = false,
  largeIcon = false,
  isSwap = false,
  ...props
}) => {
  return (
    <>
      {isSwap ? (
        <Link style={{ width: "100%" }} external {...props}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div>{children}</div>

            <div>
              {isBscScan && (
                <>
                  {largeIcon ? (
                    <div style={{ width: "20px", height: "20px" }}>
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.84322 15.7426C6.4527 16.1332 6.4527 16.7663 6.84322 17.1569C7.23374 17.5474 7.86691 17.5474 8.25743 17.1569L16.4498 8.96446L16.4498 15.0355C16.4498 15.5878 16.8975 16.0355 17.4498 16.0355C18.0021 16.0355 18.4498 15.5878 18.4498 15.0355L18.4498 6.55025C18.4498 6.28503 18.3445 6.03068 18.1569 5.84314C17.9694 5.6556 17.715 5.55025 17.4498 5.55025L8.96454 5.55025C8.41226 5.55025 7.96454 5.99796 7.96454 6.55025C7.96454 7.10253 8.41226 7.55025 8.96454 7.55025L15.0356 7.55025L6.84322 15.7426Z"
                          fill="#2E81FF"
                        />
                      </svg>
                    </div>
                  ) : (
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.0615 10.4951C4.80115 10.7554 4.80115 11.1776 5.0615 11.4379C5.32185 11.6982 5.74396 11.6982 6.0043 11.4379L11.4659 5.97631L11.4659 10.0237C11.4659 10.3919 11.7644 10.6904 12.1326 10.6904C12.5008 10.6904 12.7992 10.3919 12.7992 10.0237L12.7992 4.36683C12.7992 4.19002 12.729 4.02045 12.604 3.89543C12.4789 3.7704 12.3094 3.70017 12.1326 3.70017L6.47571 3.70017C6.10752 3.70017 5.80904 3.99864 5.80904 4.36683C5.80904 4.73502 6.10752 5.0335 6.47571 5.0335L10.5231 5.0335L5.0615 10.4951Z"
                        fill="#2e81ff"
                      />
                    </svg>
                  )}
                </>
              )}
              {isAptosScan && (
                <AptosIcon width="18" height="18" color={props.color ? props.color : "primary"} ml="4px" />
              )}
              {!isBscScan && !isAptosScan && (
                <>
                  {largeIcon ? (
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.84322 15.7426C6.4527 16.1332 6.4527 16.7663 6.84322 17.1569C7.23375 17.5474 7.86691 17.5474 8.25743 17.1569L16.4498 8.96446L16.4498 15.0355C16.4498 15.5878 16.8975 16.0355 17.4498 16.0355C18.0021 16.0355 18.4498 15.5878 18.4498 15.0355L18.4498 6.55025C18.4498 6.28503 18.3445 6.03068 18.1569 5.84314C17.9694 5.6556 17.715 5.55025 17.4498 5.55025L8.96454 5.55025C8.41226 5.55025 7.96454 5.99796 7.96454 6.55025C7.96454 7.10253 8.41226 7.55025 8.96454 7.55025L15.0356 7.55025L6.84322 15.7426Z"
                        fill="#2E81FF"
                      />
                    </svg>
                  ) : (
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.0615 10.4951C4.80115 10.7554 4.80115 11.1776 5.0615 11.4379C5.32185 11.6982 5.74396 11.6982 6.0043 11.4379L11.4659 5.97631L11.4659 10.0237C11.4659 10.3919 11.7644 10.6904 12.1326 10.6904C12.5008 10.6904 12.7992 10.3919 12.7992 10.0237L12.7992 4.36683C12.7992 4.19002 12.729 4.02045 12.604 3.89543C12.4789 3.7704 12.3094 3.70017 12.1326 3.70017L6.47571 3.70017C6.10752 3.70017 5.80904 3.99864 5.80904 4.36683C5.80904 4.73502 6.10752 5.0335 6.47571 5.0335L10.5231 5.0335L5.0615 10.4951Z"
                        fill="#2e81ff"
                      />
                    </svg>
                  )}
                </>
              )}
            </div>
          </div>
        </Link>
      ) : (
        <Link external {...props}>
          {children}

          {isBscScan && (
            <>
              {largeIcon ? (
                <div style={{ width: "20px", height: "20px" }}>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.84322 15.7426C6.4527 16.1332 6.4527 16.7663 6.84322 17.1569C7.23374 17.5474 7.86691 17.5474 8.25743 17.1569L16.4498 8.96446L16.4498 15.0355C16.4498 15.5878 16.8975 16.0355 17.4498 16.0355C18.0021 16.0355 18.4498 15.5878 18.4498 15.0355L18.4498 6.55025C18.4498 6.28503 18.3445 6.03068 18.1569 5.84314C17.9694 5.6556 17.715 5.55025 17.4498 5.55025L8.96454 5.55025C8.41226 5.55025 7.96454 5.99796 7.96454 6.55025C7.96454 7.10253 8.41226 7.55025 8.96454 7.55025L15.0356 7.55025L6.84322 15.7426Z"
                      fill="#2E81FF"
                    />
                  </svg>
                </div>
              ) : (
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.0615 10.4951C4.80115 10.7554 4.80115 11.1776 5.0615 11.4379C5.32185 11.6982 5.74396 11.6982 6.0043 11.4379L11.4659 5.97631L11.4659 10.0237C11.4659 10.3919 11.7644 10.6904 12.1326 10.6904C12.5008 10.6904 12.7992 10.3919 12.7992 10.0237L12.7992 4.36683C12.7992 4.19002 12.729 4.02045 12.604 3.89543C12.4789 3.7704 12.3094 3.70017 12.1326 3.70017L6.47571 3.70017C6.10752 3.70017 5.80904 3.99864 5.80904 4.36683C5.80904 4.73502 6.10752 5.0335 6.47571 5.0335L10.5231 5.0335L5.0615 10.4951Z"
                    fill="#2e81ff"
                  />
                </svg>
              )}
            </>
          )}
          {isAptosScan && <AptosIcon width="18" height="18" color={props.color ? props.color : "primary"} ml="4px" />}
          {!isBscScan && !isAptosScan && (
            <>
              {largeIcon ? (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.84322 15.7426C6.4527 16.1332 6.4527 16.7663 6.84322 17.1569C7.23375 17.5474 7.86691 17.5474 8.25743 17.1569L16.4498 8.96446L16.4498 15.0355C16.4498 15.5878 16.8975 16.0355 17.4498 16.0355C18.0021 16.0355 18.4498 15.5878 18.4498 15.0355L18.4498 6.55025C18.4498 6.28503 18.3445 6.03068 18.1569 5.84314C17.9694 5.6556 17.715 5.55025 17.4498 5.55025L8.96454 5.55025C8.41226 5.55025 7.96454 5.99796 7.96454 6.55025C7.96454 7.10253 8.41226 7.55025 8.96454 7.55025L15.0356 7.55025L6.84322 15.7426Z"
                    fill="#2E81FF"
                  />
                </svg>
              ) : (
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.0615 10.4951C4.80115 10.7554 4.80115 11.1776 5.0615 11.4379C5.32185 11.6982 5.74396 11.6982 6.0043 11.4379L11.4659 5.97631L11.4659 10.0237C11.4659 10.3919 11.7644 10.6904 12.1326 10.6904C12.5008 10.6904 12.7992 10.3919 12.7992 10.0237L12.7992 4.36683C12.7992 4.19002 12.729 4.02045 12.604 3.89543C12.4789 3.7704 12.3094 3.70017 12.1326 3.70017L6.47571 3.70017C6.10752 3.70017 5.80904 3.99864 5.80904 4.36683C5.80904 4.73502 6.10752 5.0335 6.47571 5.0335L10.5231 5.0335L5.0615 10.4951Z"
                    fill="#2e81ff"
                  />
                </svg>
              )}
            </>
          )}
        </Link>
      )}
    </>
  );
};

export default LinkExternal;
